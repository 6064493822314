import React, { useMemo } from 'react'
import Input, { SearchProps } from 'antd/lib/input'
import { useState } from 'react'
import debounce from 'debounce'

export function DebouncedSearchInput(props: SearchProps) {
  const { value, onChange, ...rest } = props

  const [internalValue, setInternalValue] = useState(value)

  const debouncedOnChange = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e)
      }, 600),
    [onChange]
  )

  return (
    <Input.Search
      value={internalValue}
      onChange={e => {
        setInternalValue(e.target.value)
        debouncedOnChange(e)
      }}
      {...rest}
    />
  )
}
